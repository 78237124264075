import assert from "assert"
import { Epic } from "redux-observable"
import { filter, forkJoin, from, mergeMap, of, map, catchError } from "rxjs"
import { ActionType, createAsyncAction, createReducer, isActionOf } from "typesafe-actions"
import { RootAction, RootState } from ".."
import { composeAltCode } from "../../../helper"
import { initialState, instance, RequestState } from "../utils"
import { GyeopgangViewState, set } from "../view/gyeopgang"

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

const REQUEST = 'timetable/gyeopgang/REQUEST'
const SUCCESS = 'timetable/gyeopgang/SUCCESS'
const FAILURE = 'timetable/gyeopgang/FAILURE'

export const gyeopgang = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<undefined, boolean, Error>()

const actions = { gyeopgang }
export type GyeopgangAction = ActionType<typeof actions>;

const SIZE: number = 15
export const gyeopgangEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(gyeopgang.request)),
  mergeMap(action => from(instance.get('gyeopgang')).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")

      let entries: Array<[string, number]> = Object.entries(response.data.data)
      entries = entries.sort(([, l], [, r]) => r - l).slice(0, SIZE)

      return forkJoin(entries.map(([code, sharing]) =>
        from(instance.get('auth/user', { params: { code } })).pipe(
          map(fetchresponse => {
            assert(fetchresponse.data.status === "success")

            let { name, grade, class: class_, number: num }: { name: string, grade: number, class: number, number: number } = fetchresponse.data.data

            return {
              code,
              name,
              altcode: composeAltCode(grade, class_, num),
              sharing
            } as ArrayElement<GyeopgangViewState>
          })
        )
      ))
    })
  )),
  mergeMap(data => {
    data = data.sort(({ sharing: l }, { sharing: r }) => r - l);
    return of(set(data), gyeopgang.success(true)).pipe(
      catchError(e => of(gyeopgang.failure(e)))
    )
  })
)

export default createReducer<RequestState, GyeopgangAction>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
