import React from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Label } from "semantic-ui-react";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import { gethighlight } from "../redux/reducer/timetable/gethighlight";

type GyeopgangItemProps = {
  code: string
  altcode: string
  name: string
  sharing: number
}

export const GyeopgangItem: React.FC<GyeopgangItemProps> = (props: GyeopgangItemProps) => {
  const dispatch = useDispatch();

  let color: SemanticCOLORS = "grey"
  if (props.sharing >= 5) color = "green"
  if (props.sharing >= 10) color = "blue"
  if (props.sharing >= 15) color = "violet"
  if (props.sharing >= 20) color = "pink"

  return (
    <Card>
      <Label attached='top' color={color}>{props.sharing}겹강</Label>
      <Card.Content>
        <Card.Header>{props.altcode} {props.name}</Card.Header>
        <Card.Meta>교번: {props.code}</Card.Meta>
        <Card.Description></Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button floated='right' onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          dispatch(gethighlight.request({ code: props.code }))
        }}>
          겹강 보기
        </Button>
      </Card.Content>
    </Card>

  )
}