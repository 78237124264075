import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'
import { ClassInfo, SubjectInfo } from './utils'

type ViewState = DeepReadonly<{
  subjectsinfo: SubjectInfo[]
  classesinfo: ClassInfo[]
  timetable: string[][][]
  classtable: number[][][]
  teachertable: string[][][]
  highlight: boolean[][]
  altcode?: string
  name?: string
}>

const initialState: ViewState = {
  subjectsinfo: [],
  classesinfo: [],
  timetable: [[[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []]],
  classtable: [[[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []]],
  teachertable: [[[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []]],
  highlight: [[false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false]]
}

const SETSUBJECTSINFO = 'timetable/view/SETSUBJECTSINFO'
const SETCLASSESINFO = 'timetable/view/SETCLASSESINFO'
const SETTIMETABLE = 'timetable/view/SETTIMETABLE'
const SETCLASSTABLE = 'timetable/view/SETCLASSTABLE'
const SETTEACHERTABLE = 'timetable/view/SETTEACHERTABLE'
const SETHIGHLIGHT = 'timetable/view/SETHIGHLIGHT'
const SETUSERINFO = 'timetable/view/SETUSERINFO'
const RESETHIGHLIGHT = 'timetable/view/RESETHIGHLIGHT'
const RESETALL = 'timetable/view/RESETALL'

export const setsubjectsinfo = createAction(SETSUBJECTSINFO)<DeepReadonly<SubjectInfo[]>>()
export const setclassesinfo = createAction(SETCLASSESINFO)<DeepReadonly<ClassInfo[]>>()
export const settimetable = createAction(SETTIMETABLE)<string[][][]>()
export const setclasstable = createAction(SETCLASSTABLE)<number[][][]>()
export const setteachertable = createAction(SETTEACHERTABLE)<string[][][]>()
export const sethighlight = createAction(SETHIGHLIGHT)<boolean[][]>()
export const setuserinfo = createAction(SETUSERINFO)<{ altcode: string, name: string }>()
export const resethighlight = createAction(RESETHIGHLIGHT)()
export const resetall = createAction(RESETALL)()

const actions = { setsubjectsinfo, setclassesinfo, settimetable, setclasstable, setteachertable, sethighlight, setuserinfo, resethighlight, resetall }
export type ViewAction = ActionType<typeof actions>;

export default createReducer<ViewState, ViewAction>(initialState, {
  [SETSUBJECTSINFO]: (state, action) => Object.assign({}, state, { subjectsinfo: action.payload }),
  [SETCLASSESINFO]: (state, action) => Object.assign({}, state, { classesinfo: action.payload }),
  [SETTIMETABLE]: (state, action) => Object.assign({}, state, { timetable: action.payload }),
  [SETCLASSTABLE]: (state, action) => Object.assign({}, state, { classtable: action.payload }),
  [SETTEACHERTABLE]: (state, action) => Object.assign({}, state, { teachertable: action.payload }),
  [SETHIGHLIGHT]: (state, action) => Object.assign({}, state, { highlight: action.payload }),
  [SETUSERINFO]: (state, action) => Object.assign({}, state, { altcode: action.payload.altcode, name: action.payload.name }),
  [RESETHIGHLIGHT]: (state) => Object.assign({}, state, { altcode: undefined, name: undefined, highlight: [[false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false]] }),
  [RESETALL]: () => initialState
})
