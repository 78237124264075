import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, Header, Loader } from 'semantic-ui-react'
import { RootState } from '../redux/reducer'
import { getsubjects } from '../redux/reducer/timetable/getsubjects'
import { gyeopgang } from '../redux/reducer/timetable/gyeopgang'
import { resethighlight } from '../redux/reducer/timetable/view'
import { FindForm } from './FindForm'
import { GyeopgangItem } from './GyeopgangItem'
import { TimeTable } from './TimeTable'

export const FindView: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const code_saved = useSelector((state: RootState) => state.user.code)
  const userList = useSelector((state: RootState) => state.view.gyeopgang)
  const { loading: loading_t, success: success_t } = useSelector((state: RootState) => state.timetable.getsubjects)
  const { loading: loading_g, success: success_g } = useSelector((state: RootState) => state.timetable.gyeopgang)

  useEffect(() => {
    if (code_saved === undefined) {
      history.push('/')
    }
  })

  useEffect(() => {
    dispatch(resethighlight())
    dispatch(getsubjects.request())
  }, [dispatch])

  useEffect(() => {
    if (userList.length === 0) {
      dispatch(gyeopgang.request())
    }
  }, [dispatch, userList])

  return (
    <div>
      <Header as='h2'>
        겹강 찾기
        <Header.Subheader>
          이름을 입력하여 겹강을 찾거나, 자신과 최대 겹강인 학생들을 볼 수 있습니다.
        </Header.Subheader>
      </Header>

      {
        !loading_t && success_t && !loading_g && success_g ?
          <div>
            <FindForm />
            <TimeTable highlight />
            <Card.Group className="mt-5" centered>
              {
                userList.map(({ code, name, altcode, sharing }) =>
                  <GyeopgangItem key={code} code={code} name={name} altcode={altcode} sharing={sharing} />
                )
              }
            </Card.Group>
          </div> :
          <Loader active content="정보를 가져오고 있습니다." />
      }
    </div>
  )
}